import { runInAction } from 'mobx';
import { DataCellType } from '../../shared/dto/table.dto';
import { HttpParams } from '@angular/common/http';

export interface TableColumn {
  field: string;
  type?: DataCellType;
  sortField?: string;
  translation?: boolean;
  userCount?: number;
  header: string;
  sortable?: boolean;
  size: number;
  limit?: number;
  isEnable?: boolean;
  isRequired?: boolean;
  isRawSpan?: boolean;
  isPrimaryLevel?: boolean;
}

export interface TableColumnConfigurable extends TableColumn {
  isEnable: boolean;
  isRequired: boolean;
}

export interface TableActionConfig {
  isEditable?: boolean;
  isDeletable?: boolean;
  isActivatable?: boolean;
  isDownloadable?: boolean;
}
export interface TableQuickSortItem {
  label: string;
  key: string;
  active: boolean;
}

export interface PaginationConfig {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  pageSizeOptions?: number[];
  pages: number[];
}

export interface QuickFilterItem {
  label: string;
  key: string;
  isActive: boolean;
  routerLink?: string;
}

export interface FilterCategory {
  name: string;
  key: string;
  options: FilterOption[];
  selectedCount: number;
  isExpanded: boolean;
}
export interface FilterOption {
  id: string;
  label: string;
  isSelected: boolean;
}

export interface SelectedFilterIds {
  [key: string]: number[];
}
/**
 * Using for default pagination
 */
export const defaultPaginationConfig: PaginationConfig = {
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  totalCount: 0,
  pageSizeOptions: [1, 2, 10],
  pages: [],
};

//Action config settings
export const baseActionConfig: TableActionConfig = {
  isEditable: false,
  isDeletable: false,
  isActivatable: false,
};

export const defaultActionConfig: TableActionConfig = {
  ...baseActionConfig,
  isEditable: true,
  isDeletable: true,
};

export const deleteActionConfig: TableActionConfig = {
  ...baseActionConfig,
  isDeletable: true,
};

/**
 * Generates an array of page numbers for pagination.
 * @param currentPage The current page number.
 * @param totalPages The total number of pages available.
 * @param maxVisiblePages The maximum number of pages to display at a time.
 * @returns An array of page numbers.
 */
export function generatePages(
  currentPage: number,
  totalPages: number,
  maxVisiblePages: number = 5
): number[] {
  let start = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
  let end = Math.min(start + maxVisiblePages - 1, totalPages);

  if (totalPages - start < maxVisiblePages - 1) {
    start = Math.max(totalPages - maxVisiblePages + 1, 1);
  }

  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

export function toggleColumns(
  columns: TableColumnConfigurable[],
  field: string
): void {
  const column = columns.find((c) => c.field === field);
  if (column) {
    runInAction(() => {
      column.isEnable = !column.isEnable;
    });
  }
}

/**
 * Creates a FilterCategory from a list of items, using dynamic field names and language keys.
 *
 * @param name The name of the filter category.
 * @param items The array of items to be transformed into filter options.
 * @param labelField The field name from the item object to be used as the label.
 * @param languageKey The key to determine which language's title to use.
 * @returns A FilterCategory object.
 */
export function createFilterCategory(
  name: string,
  key: string,
  items: any[],
  labelField: string = 'title',
  languageKey?: string 
): FilterCategory {
  return {
    name: name,
    key: key,
    isExpanded: false,
    selectedCount: 0,
    options: items.map((item) => ({
      id: item.id,
      label: languageKey ? item[`${labelField}_${languageKey}`] : item[labelField],
      isSelected: false,
    })),
  };
}
/**
 * Builds and returns an HttpParams object with the given parameters.
 * This function is typically used in service methods for adding params.
 *
 * @param {string} [sortField] - The field by which to sort the results.
 * @param {string} [sortOrder] - The order of sorting (e.g., 'asc' for ascending, 'desc' for descending).
 * @param {number} [currentPage] - The current page number for pagination.
 * @param {number} [pageSize] - The number of items per page for pagination.
 * @param {string} [searchQuery] - The search query string for filtering results.
 * @param {string} [quickFilterKey] - The key for a quick filter to apply to the results.
 * @param {Object.<string, number[]>} [dynamicFilters] - An object containing dynamic filters, where the key is the filter name and the value is an array of numbers representing filter criteria.
 *
 * @returns {HttpParams} - An HttpParams object containing the specified parameters.
 */
export function buildParams(
  sortField?: string,
  sortOrder?: string,
  currentPage?: number,
  pageSize?: number,
  searchQuery?: string,
  quickFilterKey?: string,
  dynamicFilters?: { [key: string]: number[] }
): HttpParams {
  let params = new HttpParams();

  if (sortField) {
    params = params.set('sort_by', sortField);
  }

  if (sortOrder) {
    params = params.set('sort_order', sortOrder);
  }

  if (currentPage !== undefined) {
    params = params.set('page', currentPage.toString());
  }

  if (pageSize !== undefined) {
    params = params.set('page_size', pageSize.toString());
  }

  if (searchQuery) {
    params = params.set('keyword', searchQuery);
  }

  if (quickFilterKey) {
    params = params.set('quick_filter', quickFilterKey);
  }

  if (dynamicFilters) {
    Object.keys(dynamicFilters).forEach((key) => {
      const values = dynamicFilters[key];
      if (values && values.length > 0) {
        params = params.set(key, values.join(','));
      }
    });
  }

  return params;
}
